import b1 from "../Assets/images/banner-icon-1-1.png";
import b2 from "../Assets/images/banner-icon-1-3.png";
import b3 from "../Assets/images/banner-icon-1-2.png";
import b4 from "../Assets/images/banner-icon-1-4.png";
import logo from "../Assets/images/logo-1-1.png";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Hash } from "crypto";

function Footer() {
  return (
    <div>
      <div style={{ marginBottom: "100px" }}></div>
      <footer className="site-footer">
        <img src={b1} alt="AwesomeImage" className="bubble-1" />
        <img src={b2} alt="AwesomeImage" className="bubble-2" />
        <img src={b3} alt="AwesomeImage" className="bubble-3" />
        <img src={b4} alt="AwesomeImage" className="bubble-4" />

        <div className="site-footer__main-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget footer-widget--about-widget">
                  <a href="index.html" className="footer-widget__footer-logo">
                    <img src={logo} alt="AwesomeImage" />
                  </a>
                  <p>+44-7508-449686</p>
                  <p>+92-51-2099227</p>

                  <p>
                    <b>Email</b> : info@codeexperts.co.uk
                  </p>
                  <p>
                    Quatro House, Lyon Way,
                    <br />
                    Firmley Road, Camberley
                    <br />
                    GU167ER, <b>United Kingdom</b>
                    <br />
                  </p>
                  <p>
                    2nd Floor Emirates Tower,
                    <br />
                    F-7 Markaz, <b>Islamabad, Pakistan</b>
                    <br />
                  </p>
                  <p>
                    Office # M9 Midcity Mall,
                    <br />
                    Satellite Town Rehmanabad,
                    <br />
                    Murree Road <b>Rawalpindi, Pakistan</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget">
                  <h3 className="footer-widget__title">Explore</h3>
                  <ul className="footer-widget__link-list">
                    <li>
                      <HashLink
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/#about"
                      >
                        About Us
                      </HashLink>
                    </li>
                    {/* <li>
                      <NavLink
                        style={(navdata) => ({
                          color: navdata.isActive ? "coral" : "",
                        })}
                        to="/teampage"
                      >
                        Team
                      </NavLink>
                    </li> */}
                    <li>
                      <HashLink
                        className="scrollToLink"
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/#screens"
                      >
                        Recent Works
                      </HashLink>
                    </li>
                    <li>
                      <NavLink
                        to="/servicespage"
                        style={(navdata) => ({
                          color: navdata.isActive ? "coral" : "",
                        })}
                        onClick={() => window.scroll(10, 10)}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/umbrellaservices"
                        style={(navdata) => ({
                          color: navdata.isActive ? "coral" : "",
                        })}
                        onClick={() => window.scroll(10, 10)}
                      >
                        Umbrella Services
                      </NavLink>
                    </li>

                    <li>
                      <HashLink
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/#freeconsultation"
                      >
                        Free Consultation
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/#contactus"
                      >
                        Contact
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget">
                  <h3 className="footer-widget__title">Services</h3>
                  <ul className="footer-widget__link-list">
                    <li>
                      <HashLink
                        to="/servicespage/#mobile"
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        onClick={() => window.scroll(10, 10)}
                      >
                        Mobile App Development
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        onClick={() => window.scroll(10, 10)}
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/servicespage/#web"
                      >
                        Web App Development
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        onClick={() => window.scroll(10, 10)}
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/servicespage/#ui"
                      >
                        UI/UX Development
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        onClick={() => window.scroll(10, 10)}
                        scroll={(el) => {
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "center",
                            });
                          }, 800);
                        }}
                        to="/servicespage/#blockchain"
                      >
                        Blockchain Development
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget">
                  <h3 className="footer-widget__title">Information</h3>
                  <ul className="footer-widget__link-list">
                    <li>
                      <a>UK VAT No: 308531615</a>
                    </li>
                    <li>
                      <a>UK Company No: 11572917</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-12 d-flex">
                <div className="footer-widget my-auto">
                  <div className="social-block">
                    <a href="/">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="https://wa.me/message/5JNHKW42PRVXC1">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                    {/* <a href="/">
                      <i className="fa fa-facebook-f"></i>
                    </a> */}
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/code-experts-ltd"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                    <a href="/">
                      <i className="fa fa-youtube-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom-footer text-center">
          <div className="container">
            <p>&copy;2022 Code Experts. All rights reserved</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
