import b1 from "../../Assets/images/banner-icon-1-6.png";
import b2 from "../../Assets/images/banner-icon-1-3.png";
import b3 from "../../Assets/images/banner-icon-1-2.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import WorkContent from "./WorkContent";
import { useEffect, useState } from "react";

const OurWork = () => {
  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} our-work__next`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "coral",
          height: "40px",
          width: "40px",
          marginRight: "68px",
          marginTop: "-75px",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} our-work__prev`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "coral",
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          marginLeft: "68px",
          marginTop: "-75px",
          zIndex: "9",
        }}
        onClick={onClick}
      />
    );
  }
  const [ourWork, setOurWork] = useState<any>([]);
  useEffect(() => {
    const fetchWorkContent = async () => {
      const response = await fetch(
        "https://codeexperts-844e9-default-rtdb.firebaseio.com/OurWork.json"
      );
      const data = await response.json();
      const work = [];
      for (const key in data) {
        work.push({
          id: key,
          name: data[key].name,
          description: data[key].description,
          img: data[key].img,
          href: data[key].href,
        });
      }
      setOurWork(work);
    };
    fetchWorkContent();
  }, []);
  var settings = {
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section
      style={{
        paddingTop: "40px",
        backgroundColor: "rgba(253, 246, 241, 0.507)",
      }}
      className="cta-two"
      id="screens"
    >
      <img src={b3} alt="AwesomeImage" className="bubble-1" />
      <img src={b1} alt="AwesomeImage" className="bubble-2" />
      <img src={b2} alt="AwesomeImage" className="bubble-3" />
      <div>
        <div className="block-title text-center">
          <div className="block-title__text">
            <span>Recent Work</span> <br />
          </div>
          <p className="cta-three__text" style={{ padding: "20px 40px" }}>
            The projects that we’ve completed so far will give you more insight
            into what we can do and how we work. Take a look at some of them
            below.
          </p>
        </div>
        <div>
          <Slider {...settings}>
            {ourWork.map((data: any) => (
              <WorkContent
                name={data.name}
                href={data.href}
                img={data.img}
                description={data.description}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default OurWork;
