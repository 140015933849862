import React from "react";
import { HashLink } from "react-router-hash-link";
import T1 from "../../Assets/images/T1.svg";
import T2 from "../../Assets/images/T2.svg";
import T3 from "../../Assets/images/T3.svg";

type Props = {};

const TopSections = (props: Props) => {
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <section className="cta-one " id="home">
          <div className=" umbrella-H-Container">
            <div className=" umbrella-Heading">
              <div className="block-title__text">
                <span>Join A Hassle-Free Umbrella Company</span>
              </div>
            </div>

            <div className="umbrella-description">
              Working through an umbrella company offers an ideal first step
              into contracting, without all the hassle and responsibility of
              paperwork.
            </div>
            <HashLink
              to="/#contactus"
              scroll={(el) => {
                setTimeout(() => {
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "center",
                  });
                }, 800);
              }}
            >
              <div style={{ cursor: "pointer" }} className=" banner-one__btn">
                Get in touch
              </div>
            </HashLink>
          </div>
          <div className="trust-div">
            <div className="trust-div-item">
              <img src={T1} />
              <div className="trust-div-content">
                <h5>100%</h5>
                <p>HRMC Compliant</p>
              </div>
            </div>
            <div className="trust-div-item">
              <img src={T2} />
              <div className="trust-div-content">
                <h5>Trusted</h5>
                <p>by over,3000 agencies</p>
              </div>
            </div>{" "}
            <div className="trust-div-item">
              <img src={T3} />
              <div className="trust-div-content">
                <h5>100,000's</h5>
                <p>of Happy Contractors</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TopSections;
