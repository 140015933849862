import React, { useState, useRef } from "react";
import b1 from "../../Assets/images/banner-icon-1-1.png";
import b2 from "../../Assets/images/banner-icon-1-2.png";

import cto1 from "../../Assets/images/cta-1-1.png";
import cto2 from "../../Assets/images/cta-1-2.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "libphonenumber-js/min";
import classes from "./RequestForm.module.css";

const isNotEmpty = (value: string) => value.trim() !== "";
const isOnlyChar = (value: string) => /^[A-Za-z]*$/.test(value);
const isOnlyCharNumber = (value: string) => /^[A-Za-z0-9]*$/.test(value);

const isEmailValid = (value: string) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);

function RequestForm() {
  const [isLoading, setIsloading] = useState<any>(false);
  const [value, setValue] = useState<any>();

  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [formInputValidity, setInputValidity] = useState<any>({
    name: true,
    email: true,
    phoneNumber: true,
    company: true,
    comments: true,
  });
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = useRef<any>(null);
  const companyInputRef = useRef<HTMLInputElement>(null);
  const commentInputRef = useRef<HTMLTextAreaElement>(null);

  const onSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const enteredName = nameInputRef.current!.value;
    const enteredCompany = companyInputRef.current!.value;
    const enteredEmail = emailInputRef.current!.value;
    const enteredPhoneNumber = phoneNumberInputRef.current!.value;
    const enteredComments = commentInputRef.current!.value;

    const enteredNameIsValid =
      isNotEmpty(enteredName) && isOnlyChar(enteredName);
    const enteredCompanyIsValid =
      isNotEmpty(enteredCompany) && isOnlyCharNumber(enteredCompany);
    const enteredEmailIsValid =
      isEmailValid(enteredEmail) && isNotEmpty(enteredEmail);
    const enteredPhoneNumberIsValid =
      isNotEmpty(enteredPhoneNumber) && isPossiblePhoneNumber(value);
    const enteredCommentIsValid = isNotEmpty(enteredComments);

    setInputValidity({
      name: enteredNameIsValid,
      email: enteredEmailIsValid,
      company: enteredCompanyIsValid,
      phoneNumber: enteredPhoneNumberIsValid,
      comments: enteredCommentIsValid,
    });

    const formIsValid =
      enteredCompany &&
      enteredNameIsValid &&
      enteredEmailIsValid &&
      enteredPhoneNumberIsValid &&
      enteredCommentIsValid;

    if (!formIsValid) {
      return;
    }
    if (formIsValid) {
      setIsloading(true);
      const response = await fetch(
        "https://codeexperts-844e9-default-rtdb.firebaseio.com/Users.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: enteredName,
            email: enteredEmail,
            company: enteredCompany,
            phoneNumber: value,
            comments: enteredComments,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("something wronb");
      }
      setIsloading(false);
      setIsSubmit(true);
      setTimeout(() => {
        setIsSubmit(false);
      }, 5000);
      nameInputRef.current!.value = "";
      emailInputRef.current!.value = "";
      phoneNumberInputRef.current!.value = "";
      commentInputRef.current!.value = "";
      companyInputRef.current!.value = "";
      setValue(null);
    }
  };

  return (
    <section className="banner-one">
      <img src={b1} alt="AwesomeImage" className="bubble-1" />
      <img src={b2} alt="AwesomeImage" className="bubble-2" />
      {/* <img src={b3} alt="AwesomeImage" className="bubble-3" /> */}
      {/* <img src={b4} alt="AwesomeImage" className="bubble-4" /> */}
      {/* <img src={b5} alt="AwesomeImage" className="bubble-5" /> */}
      {/* <img src={b6} alt="AwesomeImage" className="bubble-6" /> */}

      <div className="block-title text-center">
        <div className="block-title__text">
          <span>Get In Touch</span>
        </div>
        <div style={{ margin: "20px" }}></div>
        <h3>We’ll get back to you within 2 hours.</h3>
      </div>
      <div id="contactus" className={classes.form}>
        <div style={{ marginBottom: "100px" }} className="col-lg-6 wraphidden">
          <h1>Please Fill the Form</h1>
          <div className="cta-one__image-wrap">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="cta-one__image">
                  <img src={cto1} alt="AwesomeImage" />
                </div>
                <div className="cta-one__color-box cta-one__color-box-1"></div>
              </div>
              <div className="col-lg-6">
                <div className="cta-one__color-box cta-one__color-box-2"></div>
                <div className="cta-one__image">
                  <img src={cto2} alt="Awesome mage" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={onSubmitHandler} id="#ctn" className={classes.input}>
          <h1 className="hform">Please Fill the Form</h1>

          <div>
            <p>Your Name</p>
            <input ref={nameInputRef} placeholder="Your Name"></input>
            {!formInputValidity.name && (
              <div className={classes.invalid}>
                <p>Please Enter Valid Name</p>
              </div>
            )}
          </div>
          <div>
            <p>Email Address</p>
            <input ref={emailInputRef} placeholder="Email Address"></input>
            {!formInputValidity.email && (
              <div className={classes.invalid}>
                <p>Please Enter Your Valid Email Address</p>
              </div>
            )}
          </div>

          <div>
            <p>Company Name</p>
            <input ref={companyInputRef} placeholder="Company"></input>
            {!formInputValidity.company && (
              <div className={classes.invalid}>
                <p>Please Enter Your Valid Company Name</p>
              </div>
            )}
          </div>
          <div>
            <p>Mobile Number</p>
            <PhoneInput
              className={classes.phoneNumber}
              ref={phoneNumberInputRef}
              value={value}
              onChange={setValue}
              placeholder="Mobile Number"
            ></PhoneInput>
            {!formInputValidity.phoneNumber && (
              <div className={classes.invalid}>
                <p>Please Enter Your Mobile Number</p>
              </div>
            )}
          </div>
          <div>
            <p>Your Message</p>
            <textarea
              ref={commentInputRef}
              placeholder="Your Message"
            ></textarea>
            {!formInputValidity.comments && (
              <div className={classes.invalid}>
                <p>Please Enter Your Message</p>
              </div>
            )}
          </div>
          <div>
            <a
              href="none"
              style={{ marginTop: "20px" }}
              onClick={onSubmitHandler}
              className="banner-one__btn"
            >
              {isLoading ? "SENDING" : "ASK A QUESTION"}
            </a>
          </div>
          {isSubmit && (
            <p
              style={{
                fontWeight: "300",
              }}
            >
              We will contact you soon !!!
            </p>
          )}
        </form>
      </div>
    </section>
  );
}

export default RequestForm;
