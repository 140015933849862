// importing css
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/bootstrap.min.css";
import "./Assets/css/bootstrap.min.css.map";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import "./Assets/css/animate.css";

import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router";
import TopButton from "./Layout/TopButton";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import LoadingSpinner from "./components/LoadingSpinner";
import Home from "./Pages/Home";
import UmbrellaServices from "./Pages/UmbrellaPage/UmbrellaServices";

const ServicesPage = React.lazy(
  () => import("./Pages/ServicesPages/ServicesPage")
);
const TeamPage = React.lazy(() => import("./Pages/TeamPage"));
const TeamMember = React.lazy(() => import("./Pages/TeamMember"));
const ServiceContent = React.lazy(
  () => import("./Pages/ServicesPages/ServiceContent")
);

function App() {
  useEffect(() => {
    window.onpopstate = (event) => {
      window.location.reload();
    };
  }, []);

  return (
    <>
      <body>
        <div className="page-wrapper">
          <Navbar />
          <Suspense
            fallback={
              <div
                className="centered
              "
              >
                <LoadingSpinner />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/servicespage" element={<ServicesPage />} />
              <Route path="/teampage" element={<TeamPage />} />
              <Route path="/umbrellaservices" element={<UmbrellaServices />} />
              {/* <Route path="/team/:member" element={<TeamMember />} /> */}
              <Route
                path="/servicespage/:serviceLink"
                element={<ServiceContent />}
              />
              <Route
                path="*"
                element={
                  <div className="centered ">
                    <h1>Not Found</h1>
                  </div>
                }
              />
            </Routes>
          </Suspense>
          <Footer />
          <TopButton />
        </div>
      </body>
    </>
  );
}

export default App;
