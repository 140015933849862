import React from "react";
import RequestForm from "../../components/RequestForm/RequestForm";
import Benifits from "./Benifits";
import HowItWorks from "./HowItWorks";
import TopSections from "./TopSections";
import Why from "./Why";
type Props = {};

const UmbrellaServices = (props: Props) => {
  return (
    <>
      <TopSections />
      <Why />
      <Benifits />
      <HowItWorks />
      <RequestForm />
    </>
  );
};

export default UmbrellaServices;
