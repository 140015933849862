import b1 from "../Assets/images/b1.png";
import b2 from "../Assets/images/b2.png";
import b3 from "../Assets/images/b3.png";
import b4 from "../Assets/images/b4.png";
import b5 from "../Assets/images/b5.png";
import b6 from "../Assets/images/b6.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";

export default class BussinessPartner extends Component {
  render() {
    var settings = {
      className: "brandsize",
      infinite: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      slidesToShow: 5,
      arrows: false,
      slidesToScroll: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
      ],
    };
    return (
      <div>
        <div className="block-title text-center">
          <div className="block-title__text">
            <span>We Worked With</span> <br />
          </div>
          <div style={{ marginBottom: "70px" }}></div>
        </div>
        <Slider {...settings}>
          <div>
            <img src={b1} alt="ourwork" />
          </div>
          <div>
            <img src={b2} alt="ourwork" />
          </div>
          <div>
            <img src={b3} alt="ourwork" />
          </div>
          <div>
            <img src={b4} alt="ourwork" />
          </div>
          <div>
            <img src={b5} alt="ourwork" />
          </div>
          <div>
            <img src={b6} alt="ourwork" />
          </div>
        </Slider>
        <div style={{ marginBottom: "50px" }}></div>
      </div>
    );
  }
}
