import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../Assets/images/logo-1-1.png";
import "./SideDrawer.css";
const profile = require("../Assets/images/companyProfile.pdf");

const SideDrawer: React.FC<{ show: any; onClick: any }> = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <nav className={drawerClasses}>
      <ul
        onClick={props.onClick}
        className=" navigation-box one-page-scroll-menu "
      >
        <div className="logo-drawer">
          <img src={logo} alt="logo"></img>
        </div>
        <li className="current scrollToLink">
          <a href="/">Home</a>
        </li>
        <li className="scrollToLink">
          <NavLink onClick={() => window.scroll(5, 5)} to="/servicespage">
            Services
          </NavLink>
        </li>
        <li className="scrollToLink">
          <NavLink onClick={() => window.scroll(5, 5)} to="/umbrellaservices">
            Umbrella Services
          </NavLink>
        </li>
        <li className="scrollToLink">
          <HashLink
            className="scrollToLink"
            scroll={(el) => {
              setTimeout(() => {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "center",
                });
              }, 800);
            }}
            to="/#screens"
          >
            Recent Works
          </HashLink>
        </li>
        {/* <li className="scrollToLink">
          <NavLink onClick={() => window.scroll(5, 5)} to="/teampage">
            Team
          </NavLink>
        </li> */}
        <li className="scrollToLink">
          <HashLink
            className="scrollToLink"
            scroll={(el) => {
              setTimeout(() => {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "center",
                });
              }, 800);
            }}
            to="/#about"
          >
            About Us
          </HashLink>
        </li>
        <li className="scrollToLink">
          <a href={profile} download>
            Download Profile
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default SideDrawer;
