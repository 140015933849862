import React from "react";
import b1 from "../../Assets/images/banner-icon-1-6.png";
import b2 from "../../Assets/images/banner-icon-1-4.png";
import b3 from "../../Assets/images/banner-icon-1-5.png";
import classes from "./Calendly.module.css";

function Calendly() {
  return (
    <section
      id="freeconsultation"
      className="cta-two"
      style={{ marginBottom: "-120px" }}
    >
      <img src={b1} alt="AwesomeImage" className="bubble-1" />
      <img src={b2} alt="AwesomeImage" className="bubble-2" />
      <img src={b3} alt="AwesomeImage" className="bubble-3" />
      <div
        style={{ marginTop: "30px", marginBottom: "-50px" }}
        className="block-title text-center"
      >
        <div className="block-title__text heading">
          <span>Schedule A</span>
          <br />
          <span>Free Consultation</span>
        </div>
      </div>
      <div className={classes.calendly}>
        <iframe
          title="Calendly"
          src="https://calendly.com/codeexperts/30min"
          frameBorder="0"
        ></iframe>
      </div>
    </section>
  );
}

export default Calendly;
