import React, { useEffect, useState } from "react";
import About from "../components/About";
import BussinessPartner from "../components/BussinessPartner";
import Calendly from "../components/Calendly/Calendly";
import Hero from "../components/Hero";
import OurWork from "../components/OurWork/OurWork";
import RequestForm from "../components/RequestForm/RequestForm";
import Services from "../components/ServicesC/Services";
import Team from "../components/Team/Team";
import Vision from "../components/Vision";
import bannermoc1 from "../Assets/images/banner-moc-1.png";

function Home() {
  return (
    <body>
      <div className="page-wrapper">
        <Hero
          heading="Best Digital Agency in Europe"
          detail="We’re a dynamic team of creatives people innovation & Digital transformation experts."
          img={bannermoc1}
        />

        <Services />
        <About />
        <Vision />
        <BussinessPartner />
        <OurWork />
        {/* <Team /> */}
        <Calendly />
        <RequestForm />
      </div>
    </body>
  );
}

export default Home;
