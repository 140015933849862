import React, { useState } from "react";

function TopButton() {
  const active = "scroll-to-top scroll-to-target scroll-to-top";
  const notActive = "";
  const [show, setShow] = useState<any>([false]);
  const toggleUpbutton = () => {
    if (window.scrollY > 15) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  window.addEventListener("scroll", toggleUpbutton);
  return (
    <div
      style={{ cursor: "pointer" }}
      data-target="html"
      className={show ? active : notActive}
      onClick={() => window.scroll(5, 5)}
    >
      <i className="fa fa-angle-up"></i>
    </div>
  );
}

export default TopButton;
