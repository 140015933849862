import React from "react";
import { HashLink } from "react-router-hash-link";
import b1 from "../Assets/images/banner-icon-1-1.png";
import b2 from "../Assets/images/banner-icon-1-2.png";
import b3 from "../Assets/images/banner-icon-1-3.png";
import b4 from "../Assets/images/banner-icon-1-4.png";
import b5 from "../Assets/images/banner-icon-1-5.png";
import b6 from "../Assets/images/banner-icon-1-6.png";
const profile = require("../Assets/images/companyProfile.pdf");

const Hero: React.FC<{ heading: string; detail: string; img: any }> = (
  props
) => {
  return (
    <div>
      <section className="banner-one" id="home">
        <img src={b1} alt="AwesomeImage" className="bubble-1" />
        <img src={b2} alt="AwesomeImage" className="bubble-2" />
        <img src={b3} alt="AwesomeImage" className="bubble-3" />
        <img src={b4} alt="AwesomeImage" className="bubble-4" />
        <img src={b5} alt="AwesomeImage" className="bubble-5" />
        <img src={b6} alt="AwesomeImage" className="bubble-6" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "0px",
            width: "50px",
          }}
        >
          <img src={props.img} className="banner-one__moc" alt="Awesome" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                style={{ marginTop: "-80px" }}
                className="banner-one__content"
              >
                <div className="block-title">
                  <div className="block-title__text al">
                    <span>{props.heading}</span>
                  </div>
                </div>
                {/* <!-- /.block-title --> */}
                <p className="banner-one__text al">{props.detail}</p>
                {/* <!-- /.banner-one__text --> */}
                <div className="hero-flex">
                  <HashLink
                    to="/#contactus"
                    scroll={(el) => {
                      setTimeout(() => {
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "center",
                        });
                      }, 800);
                    }}
                    className="banner-one__btn__hero"
                  >
                    Get In Touch
                  </HashLink>
                  <a href={profile} download className="banner-one__btn__hero">
                    Company Profile
                  </a>
                </div>
              </div>
              {/* <!-- /.banner-one__content --> */}
            </div>
            {/* <!-- /.col-lg-5 --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
    </div>
  );
};

export default Hero;
