// import styles from "./Navbar.module.css";
import logo from "../Assets/images/logo-1-1.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import SideDrawer from "./SideDrawer";
import BackDrop from "./BackDrop";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [navbar, setNavbar] = useState<any>([false]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prev) => !prev);
  };
  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };
  var active =
    "navbar navbar-expand-lg navbar-light header-navigation stricky stricky-fixed slideInDown animated";
  var stick = "navbar navbar-expand-lg navbar-light header-navigation stricky";
  const toggleNavbar = () => {
    if (window.scrollY > 15) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", toggleNavbar);
  return (
    <>
      <header className="site-header header-one">
        <nav className={navbar ? active : stick}>
          <div className="container clearfix">
            {/* <!-- Brand and toggle get grouped for better mobile display --> */}
            <div className="logo-box clearfix">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="main-logo" alt="Awesome"></img>
              </NavLink>
              {/* this is phone menu burger */}
              <button
                className="menu-toggler"
                data-target=".header-one .main-navigation"
                onClick={drawerToggleClickHandler}
              >
                <span className="fa fa-bars"></span>
              </button>
            </div>
            {/* <!-- /.logo-box --> */}
            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div className="main-navigation">
              <ul className=" navigation-box one-page-scroll-menu ">
                <li className="scrollToLink">
                  <NavLink
                    onClick={() => window.scroll(10, 10)}
                    style={(navdata) => ({
                      color: navdata.isActive ? "coral" : "",
                    })}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="scrollToLink">
                  <NavLink
                    to="/servicespage"
                    style={(navdata) => ({
                      color: navdata.isActive ? "coral" : "",
                    })}
                    onClick={() => window.scroll(10, 10)}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="scrollToLink">
                  <NavLink
                    to="/umbrellaservices"
                    style={(navdata) => ({
                      color: navdata.isActive ? "coral" : "",
                    })}
                    onClick={() => window.scroll(10, 10)}
                  >
                    Umbrella Services
                  </NavLink>
                </li>
                <li className="scrollToLink">
                  <HashLink
                    className="scrollToLink"
                    scroll={(el) => {
                      setTimeout(() => {
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "center",
                        });
                      }, 800);
                    }}
                    to="/#screens"
                  >
                    Recent Works
                  </HashLink>
                </li>
                {/* <li className="scrollToLink">
                  <NavLink
                    style={(navdata) => ({
                      color: navdata.isActive ? "coral" : "",
                    })}
                    to="/teampage"
                    onClick={() => window.scroll(10, 10)}
                  >
                    Team
                  </NavLink>
                </li> */}
                <li className="scrollToLink">
                  {/* <a href="/#about">About Us</a> */}
                  <HashLink
                    className="scrollToLink"
                    scroll={(el) => {
                      setTimeout(() => {
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        });
                      }, 800);
                    }}
                    to="/#about"
                  >
                    About Us
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="right-side-box">
              <HashLink
                scroll={(el) => {
                  setTimeout(() => {
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }, 800);
                }}
                to="/#contactus"
                className="header-one__btn"
              >
                Contact
              </HashLink>
              <HashLink
                scroll={(el) => {
                  setTimeout(() => {
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }, 800);
                }}
                to="/#freeconsultation"
                className="header-one__btn"
              >
                Free Consultation
              </HashLink>
            </div>
          </div>
          {/* <!-- /.container --> */}
        </nav>
        {/* </nav> */}
      </header>
      <SideDrawer show={sideDrawerOpen} onClick={backdropClickHandler} />
      {sideDrawerOpen && <BackDrop onClick={backdropClickHandler} />}
    </>
  );
}

export default Navbar;
