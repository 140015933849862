import b1 from "../Assets/images/banner-icon-1-6.png";
import b2 from "../Assets/images/banner-icon-1-4.png";
import b3 from "../Assets/images/banner-icon-1-5.png";
import moc from "../Assets/images/cta-moc-1-1.png";

function About() {
  return (
    <section id="about" className="cta-three">
      <img src={b1} alt="AwesomeImage" className="bubble-1" />
      <img src={b2} alt="AwesomeImage" className="bubble-2" />
      <img src={b3} alt="AwesomeImage" className="bubble-3" />
      <div className="container">
        <img src={moc} alt="AwesomeImage" className="cta-three__moc" />
        <div className="row no-gutters justify-content-end">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex">
            <div className="cta-three__content my-auto">
              <div className="block-title">
                <div className="block-title__text">
                  <span>Our History</span>
                </div>
              </div>
              <p className="cta-three__text">
                Code Experts was founded by developers who are knowledgeable and
                experienced in many platforms from iOS, Android, Windows, Web
                and Ethereum Blockchain! As people who love information
                technology and everything to do with it.
                <br />
                <br />
                We’d consider it an injustice if our solutions and services
                aren’t able to give you an idea of the affinity that we have for
                the world of development and IT consultancy.
                <br />
                <br />
                That’s exactly why our professionalism and expertise reflects
                through all of the solutions and services that we deliver to our
                clients allowing us to exceed expectations at every step of the
                way.
              </p>
              {/* <ul className="cta-three__list">
                <li className="cta-three__list-item">
                  <i className="fa fa-check"></i>Code Experts was founded by
                  developers who are knowledgeable
                </li>
                <li className="cta-three__list-item">
                  <i className="fa fa-check"></i>and experienced in many
                  platforms from iOS, Android,
                </li>
                <li className="cta-three__list-item">
                  <i className="fa fa-check"></i> Windows, Web and Ethereum
                  Blockchain!
                </li>
              </ul> */}
              {/* <a href="#contactus" className="cta-three__btn">
                +44 7508 449686
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
