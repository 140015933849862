import React from "react";
import W1 from "../../Assets/images/W1.svg";
import W2 from "../../Assets/images/W2.svg";
import W3 from "../../Assets/images/W3.svg";
import W4 from "../../Assets/images/W4.svg";

type Props = {};

const Why = (props: Props) => {
  return (
    <>
      <section className="cta-two container">
        <div className="block-title text-center">
          <p style={{ textAlign: "left" }}></p>
          <div className="service-content">
            <div>
              <h1>
                <h1 style={{ color: "#FE8257" }}>Why</h1>Code Experts?
              </h1>
            </div>
          </div>
          <div className="u-card">
            <div className="u-card-item">
              <div className="u-card-heading">
                <img src={W1}></img>
                <h3>Support that’s second to none</h3>
              </div>
              <div className="u-card-content">
                With hundreds of expert head office staff, we are passionate
                about excellent support and dedicated to ensuring you always get
                paid on time and in full. And, after all these years, we know
                all legislative and compliance regulations inside out.
              </div>
            </div>
            <div className="u-card-item">
              <div className="u-card-heading">
                <img src={W2}></img>

                <h3>Don’t just take our word for it</h3>
              </div>
              <div className="u-card-content">
                We partner with over 3,000 recruitment agencies, including
                leading companies like Hays, Search, Venn and Reed, all of whom
                trust us implicitly with the contractors they refer to us.
              </div>
            </div>{" "}
            <div className="u-card-item">
              <div className="u-card-heading">
                <img src={W3}></img>

                <h3>Benefits included as standard</h3>
              </div>
              <div className="u-card-content">
                Relax knowing you’re covered for up to £20 million of insurance
                including employer’s liability, professional indemnity and
                public liability.
              </div>
            </div>{" "}
            <div className="u-card-item">
              <div className="u-card-heading">
                <img src={W4}></img>

                <h3>Set up in under 10 minutes</h3>
              </div>
              <div className="u-card-content">
                With all the right information from you and your agency, you can
                be up and running in under 10 minutes.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Why;
