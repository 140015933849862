import React from "react";

const WorkContent: React.FC<{
  img: any;
  name: string;
  description: string;
  href: string;
}> = (props) => {
  return (
    <div className="contcard">
      <div className="card">
        <a className="imgBx" href={props.href} target="_blank">
          <img src={props.img} alt="card"></img>
          <div className="flcent">
            <p className="show">{props.name}</p>
          </div>
        </a>
        <div className="content">
          <div className="flcenth">
            <a href={props.href}>{props.name}</a>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkContent;
