import React from "react";
import b1 from "../../Assets/images/banner-icon-1-1.png";
import b2 from "../../Assets/images/banner-icon-1-3.png";
import b3 from "../../Assets/images/banner-icon-1-2.png";
import b4 from "../../Assets/images/banner-icon-1-4.png";

type Props = {};

const HowItWorks = (props: Props) => {
  return (
    <>
      <section className="site-footer">
        <img src={b1} alt="AwesomeImage" className="bubble-1" />
        <img src={b2} alt="AwesomeImage" className="bubble-2" />
        <img src={b3} alt="AwesomeImage" className="bubble-3" />
        <img src={b4} alt="AwesomeImage" className="bubble-4" />
        <section className="container">
          <div className="block-title text-center">
            <p style={{ textAlign: "left" }}></p>
            <div className="service-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "40px",
                }}
              >
                <h1 style={{ textAlign: "center" }}>How it works?</h1>

                <p
                  style={{
                    textAlign: "center",
                    maxWidth: "60%",
                    marginTop: "40px",
                  }}
                >
                  Signing up to be a Code experts employee could not be simpler.
                  Our standard joining process is designed to be straight
                  forward ensuring minimal disruption.
                </p>
              </div>
            </div>
            <div
              className="u-card"
              style={{ flexDirection: "column", gap: "40px" }}
            >
              <div className="u-card-content u-card-border">
                <div>
                  <div>
                    <h3 style={{ color: "#2E3D62" }}>1st Step</h3>
                  </div>
                  Get in touch with a member of our specialist Best Advice team
                  who'll talk you through how we work and ask you to complete
                  our set up form with some basic information.
                </div>
              </div>
              <div className="u-card-content u-card-border">
                <div>
                  <div>
                    <h3 style={{ color: "#2E3D62" }}>2nd Step</h3>
                  </div>
                  Have your ID ready and we'll coordinate all onboarding
                  information and send you an assignment schedule and contract
                  of employment for you to sign.
                </div>{" "}
              </div>
              <div className="u-card-content u-card-border">
                <div>
                  <div>
                    <h3 style={{ color: "#2E3D62" }}>3rd Step</h3>
                  </div>
                  Once signed, you are fully employed by Code experts and ready
                  to be paid. its our responsibility to make things easy for
                  you.
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default HowItWorks;
