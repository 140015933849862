import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const ServicesData: React.FC<{
  heading: string;
  detail: string;
  icon: any;
  link: any;
}> = (props) => {
  return (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
      <HashLink
        to={props.link}
        onClick={() => window.scrollTo(10, 10)}
        scroll={(el) => {
          setTimeout(() => {
            el.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }, 800);
        }}
      >
        <div className="service-one__single">
          <i className={props.icon}></i>
          <h3 className="service-one__title">
            <a href={props.link}>{props.heading}</a>
          </h3>
          <p className="service-one__text">{props.detail}</p>

          <HashLink to={props.link} className="service-one__link">
            <i style={{ color: "coral" }} className="nonid-icon-left-arrow"></i>
          </HashLink>
        </div>
      </HashLink>
    </div>
  );
};

export default ServicesData;
