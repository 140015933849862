import React, { useState } from "react";
import b1 from "../../Assets/images/banner-icon-1-6.png";
import b2 from "../../Assets/images/banner-icon-1-3.png";
import b3 from "../../Assets/images/banner-icon-1-2.png";

type Props = {};

const Benifits = (props: Props) => {
  const [show, setShow] = useState<any>(true);
  const onClick = () => {
    setShow((prev: any) => !prev);
  };
  return (
    <>
      <section
        style={{
          paddingTop: "40px",
          backgroundColor: "rgba(253, 246, 241, 0.507)",
        }}
        className="cta-two"
        id="screens"
      >
        <img src={b3} alt="AwesomeImage" className="bubble-1" />
        <img src={b1} alt="AwesomeImage" className="bubble-2" />
        <img src={b2} alt="AwesomeImage" className="bubble-3" />

        {/* <section className="cta-two container"> */}

        <div className="block-title text-center container">
          <div className="service-content">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ textAlign: "center" }}>The Benifits of Using</h1>

              <h1 style={{ textAlign: "center" }}>an Umbrella Company</h1>

              <p
                style={{
                  textAlign: "center",
                  maxWidth: "70%",
                  marginTop: "30px",
                }}
              >
                There are a variety of benefits to joining an umbrella company.
                You will enjoy the same protections and benefits as any other
                employee but have the freedom of being a contractor.
              </p>
            </div>
          </div>
          <div className="benifit-card">
            <div className="benifit-card-item">
              <div className="benifit-card-heading">
                <h3>Reduced Stress</h3>
              </div>
              <div className="benifit-card-content">
                <div>
                  Code experts wants to make your business as easy to manage as
                  possible. When you join, there’s no daily admin to agonise
                  over – you can simply submit your timesheet each week via
                  MyCode experts, our easy-to-use online portal, and we’ll take
                  care of the rest.
                </div>
              </div>
            </div>
            <div className="benifit-card-item">
              <div className="benifit-card-heading">
                <h3>Financial Support</h3>
              </div>
              <div className="benifit-card-content">
                All of your finances are managed and taxes will automatically be
                deducted, so there will be no nasty surprises. Working as an
                umbrella employee also means that IR35 will not be relevant to
                you which is a great relief.
              </div>
            </div>{" "}
            <div className="benifit-card-item">
              <div className="benifit-card-heading">
                <h3>Simplicity</h3>
              </div>
              <div className="benifit-card-content">
                Your payslip will show a breakdown of the money you have earned
                and any deductions taken for tax. All of the deductions will be
                clearly displayed on your statement and your wages will be paid
                directly into your bank account.
              </div>
            </div>{" "}
            <div className="benifit-card-item">
              <div className="benifit-card-heading">
                <h3>Flexibility</h3>
              </div>
              <div className="benifit-card-content">
                Working with an umbrella company is a great way to test the
                contracting life without the financial demands of running your
                own limited company. Being a Code experts employee is also an
                ideal solution if you are working shorter contracts.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benifits;
