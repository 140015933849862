import cto1 from "../Assets/images/cta-1-1.png";
import cto2 from "../Assets/images/cta-1-2.png";

function Vision() {
  return (
    <section className="cta-one" style={{ marginBottom: "100px" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-one__image-wrap wraphidden">
              <div className="row no-gutters">
                <div className="col-lg-6">
                  <div className="cta-one__image">
                    <img src={cto1} alt="AwesomeImage" />
                  </div>
                  <div className="cta-one__color-box cta-one__color-box-1"></div>
                </div>
                <div className="col-lg-6 ">
                  <div className="cta-one__color-box cta-one__color-box-2"></div>
                  <div className="cta-one__image">
                    <img src={cto2} alt="Awesome Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex">
            <div className="cta-one__content my-auto">
              <div className="block-title">
                <div className="block-title__text">
                  <span>Our Vision</span> <br />
                </div>
                <div className="visionh">
                  <img src={cto1} alt="AwesomeImage" />
                </div>
              </div>
              <p className="cta-one__text">
                Our vision for Consultancy Service is to offer the best services
                to clients looking to innovate and solve unique problems, by
                providing them with world-class consultancy to develop fast,
                robust, maintainable and scalable products for web, mobile and
                Blockchain.
                <div className="visionh">
                  <img src={cto2} alt="AwesomeImage" />
                </div>
                <br />
                <br />
                Our vision for Umbrella Service is to create a platform, where
                IT contractors can find help to secure new contracts with high
                efficiency and without going through the hassle of paperwork.
                Additionally, we have the vision to help them excel by arranging
                boot camps and high-value training.
              </p>

              {/* <ul className="cta-one__list">
                <li className="cta-one__list-item">
                  <i className="fa fa-check"></i>IT Consulting
                </li>

                <li className="cta-one__list-item">
                  <i className="fa fa-check"></i>Ethereum blockchain & Smart
                  Contracts
                </li>
                <li className="cta-one__list-item">
                  <i className="fa fa-check"></i>We provide wide range of
                  Mobile, web/blockchain dev
                </li>
              </ul> */}
              {/* <a href="#contactus" className="cta-one__btn">
                +44 7508 449686
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Vision;
