import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ServicesData from "./ServicesData";
function Services() {
  const [serviceData, setServiceData] = useState<any>([]);

  useEffect(() => {
    const fetchServiceContent = async () => {
      const response = await fetch(
        "https://codeexperts-844e9-default-rtdb.firebaseio.com/Services.json"
      );
      const data = await response.json();
      const services = [];
      for (const key in data) {
        services.push({
          id: key,
          heading: data[key].heading,
          detail: data[key].detail,
          icon: data[key].icon,
          link: data[key].link,
        });
      }
      setServiceData(services);
    };
    fetchServiceContent();
  }, []);
  return (
    <>
      <section className="service-one" id="services">
        <div className="container">
          <div className="block-title text-center">
            <div className="block-title__text">
              <span>Our Services</span>
              <div style={{ paddingBottom: "25px" }}></div>
            </div>
            <p style={{ textAlign: "left" }} className="cta-three__text">
              We offer a wide range of solutions from native iOS and Android
              applications based on Swift and Kotlin to cross-platform solutions
              based on Flutter and React Native and web apps based on Node.js,
              React, and Angular. For all these solutions, we create beautiful
              designs, all thanks to our quality UI/UX team. We place testing at
              the core of our development process, from writing unit tests to
              performing manual and automated tests, thanks to our expert
              quality assurance team.
            </p>
            <br />
            <br />
            <div className="row">
              {serviceData.map((service: any) => (
                <ServicesData
                  key={service.id}
                  icon={service.icon}
                  heading={service.heading}
                  detail={service.detail}
                  link={service.link}
                />
              ))}
            </div>
          </div>
          <div className="servicei-sm-btn">
            <NavLink
              to="/servicespage"
              onClick={() => window.scroll(10, 10)}
              className="banner-one__btn"
            >
              Check Out Our Services
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
